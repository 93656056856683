<template>
  <v-container>
    <!-- 
      <my-toolbar-progress-bar
      :isLoading="isLoading"
      :color="toolbareColor"
      slot="extension"
    />
    <template>
      <v-bottom-navigation color="primary" horizontal>
        <v-btn
          :to="{ name: 'Prosjekt', params: { FirmaID: this.currentFirmaID } }"
          :disabled="this.currentFirmaID == -1"
        >
          <span>Prosjekt</span>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn
          :to="{
            name: 'ProsjektFirma',
            params: { FirmaID: this.currentFirmaID }
          }"
          :disabled="this.currentFirmaID == -1"
        >
          <span>ProsjektFirma</span>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template> -->
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Søk"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="dataList"
      class="elevation-1"
      @click:row="rowClick"
      item-key="FirmaID"
      single-select
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Firma - {{ currentHeading }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Ny</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <dlgFirma
                      v-if="dialog"
                      v-model="editedItem"
                      :editMode="editedIndex"
                    />
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
                <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>

    <v-tabs fixed-tabs v-model="activeTab">
      <v-tab key="Avdeling">Avdeling</v-tab>
      <v-tab key="Bruker">Bruker</v-tab>
      <!-- <v-tab key="Prosjekt">Prosjekt</v-tab> -->

      <v-tab-item key="Avdeling">
        <template v-if="currentItem">
          <Avdeling v-model="currentFirmaID"></Avdeling>
        </template>
      </v-tab-item>
      <v-tab-item key="Bruker">
        <template v-if="currentItem">
          <Bruker v-model="currentFirmaID"></Bruker>
        </template>
      </v-tab-item>
      <!-- <v-tab-item key="Prosjekt">
        <template v-if="currentItem">
          <Prosjekt v-model="currentFirmaID" ></Prosjekt>
        </template>
      </v-tab-item> -->
    </v-tabs>
  </v-container>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";
//import MyToolbarProgressBar from "@/components/MyToolbarProgressBar.vue";

export default {
  extends: KeyValue,
  components: {
    dlgFirma: () => import("../dialog/dlgFirma"),

    Avdeling: () => import("./Avdeling"),
    Bruker: () => import("./Bruker"),
    // Prosjekt: () => import("./Prosjekt"),

    //MyToolbarProgressBar
  },
  data() {
    return {
      search: "",
      activeTab: [],
      isLoading: false,
      toolbareColor: "red",
      dialog: false,
      headers: [
        { text: "FirmaRolle", value: "FirmaRolleID" },
        { text: "Region", value: "RegionID" },
        { text: "Firma navn", value: "FirmaNavn" },
        { text: "Org. Nr.", value: "OrgNr" },
        { text: "Mor firma", value: "ParentFirmaID" },
        { text: "Chicken kunde", value: "ChickenKunde" },
        { text: "Gate addresse", value: "GateAddresse" },
        { text: "Post addresse", value: "PostAddresse" },
        { text: "Postnummer", value: "PostNummer" },
        { text: "E-post", value: "FirmaMail" },
        { text: "Faktura E-post", value: "FakturaMail" },
        { text: "Web", value: "Web" },
        { text: "Aksjon", value: "actions", sortable: false },
      ],
      currentFirmaID: -1,
      currentHeading: "",
      currentItem: "",
      dataList: [],
      editedIndex: -1,
      editedItem: {
        FirmaID: "",
        FirmaRolleID: "",
        RegionID: "",
        FirmaNavn: "",
        OrgNr: "",
        ParentFirmaID: "",
        ChickenKunde: "",
        GateAddresse: "",
        PostAddresse: "",
        PostNummer: "",
        FirmaMail: "",
        FakturaMail: "",
        Web: "",
      },
      defaultItem: {
        FirmaID: "",
        FirmaRolleID: "",
        RegionID: "",
        FirmaNavn: "",
        OrgNr: "",
        ParentFirmaID: "",
        ChickenKunde: "",
        GateAddresse: "",
        PostAddresse: "",
        PostNummer: "",
        FirmaMail: "",
        FakturaMail: "",
        Web: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny" : "Endre";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    rowClick: function (item, row) {
      console.log("Firma - Row click");
      this.currentFirmaID = item.FirmaID;
      this.currentHeading = item.FirmaNavn;
      this.currentItem = item;
      //this.editedIndex = this.dataList.indexOf(item);
      row.select(true);
      this.$root.$emit("Firma", item.FirmaID, item.FirmaNavn);
    },
    initEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    initialize() {
      this.isLoading = true;
      this.GetFirmaRolleCode();
      this.GetPostNummerCode();
      this.GetRegionCode();
      this.GetData();
      this.initEditItem();
      this.isLoading = false;
    },
    GetFirma(FirmaID) {
      axios({
        method: "get",
        params: { FirmaID: FirmaID },
        url: DataSourceURL + "/Firma/GetFirma",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItem = res.data.DataList;
            this.dialog = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Firma/GetFirma. See details: ${err}`
          );
        });
    },

    GetData() {
      axios({
        method: "get",
        url: DataSourceURL + "/Firma/GetData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.dataList = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /Firma/GetData. See details: ${err}`
          );
        });
    },

    editItem(item) {
      this.editedIndex = this.dataList.indexOf(item);
      this.GetFirma(item.FirmaID);
      //this.editedItem = Object.assign({}, item)
      //this.dialog = true
    },

    DeleteData(index, FirmaID) {
      axios({
        method: "delete",
        url: DataSourceURL + "/Firma/DoDelete",
        params: { FirmaID: FirmaID },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "DELETE",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.dataList.splice(index, 1);
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Firma/Delete. See details: ${err}`
          );
        });
    },

    deleteItem(item) {
      const index = this.dataList.indexOf(item);
      if (
        confirm(
          "Are you sure you want to delete this item? " + item.FirmaRolleID
        ) == true
      ) {
        this.DeleteData(index, item.FirmaID);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },

    DoSave() {
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editedIndex > -1) {
        myUrl = DataSourceURL + "/Firma/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Firma/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: this.editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK

              //Ordre kode felt
              this.editedItem.FirmaRolleID = this.FindDescription(
                this.editedItem.FirmaRolleID,
                this.FirmaRolleItems
              );
              this.editedItem.PostNummer = this.FindDescription(
                this.editedItem.PostNummer,
                this.PostNummerItems
              );
              this.editedItem.RegionID = this.FindDescription(
                this.editedItem.RegionID,
                this.RegionItems
              );
              if (this.editedIndex > -1) {
                Object.assign(this.dataList[this.editedIndex], this.editedItem); //Oppdatering
              } else {
                this.editedItem.FirmaID = res.data.ID;
                this.dataList.push(this.editedItem); //Ny
              }
              this.close();
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    save() {
      this.DoSave();
    },
  },
};
</script>
